import { Slot } from '@radix-ui/react-slot'
import React, { ComponentProps, forwardRef, PropsWithChildren } from 'react'
import { cn } from '@sq/components'

type ButtonProps = ComponentProps<'button'> & {
  asChild?: boolean
}

const positionToStyle = {
  left: 'mr-auto items-start',
  right: 'ml-auto w-auto items-end',
} as const

const PromptMessageButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        {...props}
        ref={ref}
        className={cn(
          'inline-flex min-w-[166px] items-center justify-center gap-2 rounded-lg border-2 border-sq-gray-600 bg-white px-6 py-4 text-center font-bold text-sq-gray-600 transition-all duration-300 hover:bg-sq-gray-100',
          className
        )}
      >
        {children}
      </Comp>
    )
  }
)

PromptMessageButton.displayName = 'PromptMessageButton'

type MessagePosition = 'left' | 'right'

type Props = {
  position: MessagePosition
}

export default function PromptMessage({ position, children }: PropsWithChildren<Props>) {
  return <div className={cn('flex flex-col gap-2', positionToStyle[position])}>{children}</div>
}

PromptMessage.Button = PromptMessageButton
