import { cn } from '@sq/components'

type Props = {
  count: number
  active?: number
  className?: string
}

export default function Dots({ count, active, className }: Props) {
  return (
    <div className={cn('mx-auto inline-flex gap-5', className)}>
      {Array.from({ length: count })
        .fill(1)
        .map((_, i) => (
          <div
            key={i}
            className={cn(
              'size-3 rounded-full bg-sq-gray-200',
              !!active && i <= active - 1 && 'bg-sq-gray-900'
            )}
          />
        ))}
    </div>
  )
}
