import { XMarkIcon } from '@heroicons/react/24/outline'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react'
import { cn } from '@sq/components'

export const Dialog = DialogPrimitive.Root

export const DialogTrigger = DialogPrimitive.Trigger

export const DialogPortal = DialogPrimitive.Portal

export const DialogClose = DialogPrimitive.Close

function _DialogOverlay(
  { className, ...props }: ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>,
  ref: Ref<HTMLDivElement>
) {
  return (
    <DialogPrimitive.Overlay
      ref={ref}
      className={cn(
        'fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
        className
      )}
      {...props}
    />
  )
}

export const DialogOverlay = forwardRef(_DialogOverlay)

function _DialogContent(
  { className, children, ...props }: ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
  ref: Ref<HTMLDivElement>
) {
  return (
    <DialogPortal>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
          'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]',
          'fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 rounded-2xl border bg-white p-4 shadow-lg duration-200 sm:p-6',
          className
        )}
        {...props}
      >
        <div className="grid w-full gap-4 overflow-auto">{children}</div>
        <DialogPrimitive.Close className="data-[state=open]:text-muted-foreground absolute right-2 top-3 ring-offset-white focus:outline-none focus:ring-2 focus:ring-sq-gray-100 focus:ring-offset-2 disabled:pointer-events-none sm:right-4 sm:top-4 sm:rounded-2xl">
          <XMarkIcon className="size-6 sm:size-8" />
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogPortal>
  )
}

export const DialogContent = forwardRef(_DialogContent)
