import React, { useMemo } from 'react'
import { cn } from '@sq/components'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Button from '../../Button/Button'

type Props = {
  options: ReadonlyArray<string>
  selectedOptions: ReadonlyArray<number> | ReadonlySet<number>
  onToggle: (index: number) => void
  className?: string
}

export default function CheckButtonGroup({ options, selectedOptions, className, onToggle }: Props) {
  const selectedOptionsSet = useMemo(() => {
    if (selectedOptions instanceof Set) {
      return selectedOptions
    }

    return new Set(selectedOptions)
  }, [selectedOptions])

  return (
    <div className={cn('flex flex-row flex-wrap items-start gap-2', className)}>
      {options.map((option, index) => (
        <Button
          key={option}
          variant="gray"
          onClick={() => onToggle(index)}
          className={cn(
            'w-auto text-base font-normal',
            selectedOptionsSet.has(index) && 'bg-sq-gray-300'
          )}
        >
          {option}
          {selectedOptionsSet.has(index) && <XMarkIcon className="size-4" />}
        </Button>
      ))}
    </div>
  )
}
