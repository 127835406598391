import { LinkProps } from 'next/dist/client/link'
import Link from 'next/link'
import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react'

import styles from './SQLink.module.scss'

interface SQLink extends LinkProps, PropsWithChildren, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> {
    className?: string
    withoutStyles?: boolean
    prefetch?: boolean
}

function SQLink({ className, withoutStyles, prefetch = false, children, ...props }: SQLink) {
    return (
        <Link
            {...props}
            className={`${!withoutStyles ? styles.SQLink : ''} ${className ? className : ''}`}
            prefetch={prefetch}
        >
            {children}
        </Link>
    )
}

export default SQLink
