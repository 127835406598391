import { InformationCircleIcon } from '@heroicons/react/24/outline'
import React, { PropsWithChildren } from 'react'
import { cn } from '@sq/components'

type Props = {
  children: React.ReactNode
  className?: string
}

export default function InfoCallout({ children, className }: PropsWithChildren<Props>) {
  return (
    <div
      className={cn(
        'my-2 flex flex-row gap-2 rounded-2xl border border-sq-gray-200 px-4 py-6',
        className
      )}
    >
      <div>
        <InformationCircleIcon height={24} width={24} className="text-sq-gray-900" />
      </div>
      <div className="flex flex-1 flex-col gap-1">{children}</div>
    </div>
  )
}
