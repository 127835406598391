import React, { ComponentProps } from 'react'
import { Button, cn } from '@sq/components'

type Props = Pick<ComponentProps<'button'>, 'onClick' | 'className'> & {
  icon: React.ReactNode
  description: string
  buttonText: string
}

export default function Banner({ icon, description, buttonText, className, onClick }: Props) {
  return (
    <div
      className={cn(
        'flex w-full min-w-[260px] flex-col items-center justify-center gap-7 rounded-3xl bg-sq-gray-50 px-4 py-6 md:max-w-screen-xl md:flex-row',
        className
      )}
    >
      <div className="flex gap-4">
        <div>{icon}</div>
        <div className="text-lg md:text-xl">{description}</div>
      </div>
      <Button variant="gray" onClick={onClick} className="max-w-[275px] text-sm font-bold">
        {buttonText}
      </Button>
    </div>
  )
}
