import React from 'react'

import styles from './SQDropdown.module.scss'

type SQDropdownItemOptions = {
    itemClasses?: string
    closeOnClick?: boolean
}

export interface SQDropdownItemProps {
    children: string
    onClick: () => void
    options?: SQDropdownItemOptions
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

function SQDropdownItem({ children, onClick, options, setOpen }: SQDropdownItemProps) {
    return (
        <li
            className={`${styles.item} ${options?.itemClasses}`}
            onClick={() => {
                onClick()
                options?.closeOnClick && setOpen && setOpen(false)
            }}
        >
            {children}
        </li>
    )
}

export default SQDropdownItem
